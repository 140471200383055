import { useState, useEffect } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { useHistory } from "react-router-dom";
import { scroller } from "react-scroll";

const Header = ({ t }: any) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [visible, setVisibility] = useState(false);
  const history = useHistory();
  const scrollTarget = (target: any) =>
    scroller.scrollTo(target, { smooth: true, duration: 700, offset: -50 });

  const isMobile = () => {
    return window.innerWidth <= 768; // Adjust the device widtdh if needed
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!isMobile() && visible) {
        setVisibility(false);
      }
    };

    document.addEventListener("resize", handleVisibilityChange);
    return () => {
      document.removeEventListener("resize", handleVisibilityChange);
    };
  }, [visible]);

  const showDrawer = () => {
    if (isMobile()) {
      setVisibility(!visible);
    }
  };

  const onClose = () => {
    if (isMobile()) {
      setVisibility(!visible);
    }
  };

  const MenuItem = () => {
    const scrollToPage = async (target: any) => {
      if (history.location.pathname !== "/") {
        await history.push("/");
      }
      scrollTarget(target);
    };

    const checkOtp = () => {
      const code = sessionStorage.getItem("otp");
      if (code) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    };

    useEffect(() => {
      checkOtp();
    }, []);

    const handleButtonClick = () => {
      if (loggedIn) {
        sessionStorage.removeItem("otp");
        setLoggedIn(false);
        history.push("/");
      } else {
        setLoggedIn(true);
        history.push("/login");
      }
    };
    return (
      <>
        <CustomNavLinkSmall onClick={() => scrollToPage("product")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollToPage("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollToPage("about")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => scrollToPage("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "80px" }}
          onClick={handleButtonClick}
        >
  
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="logosjk.png" width="195px" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <div onClick={onClose}>
            <MenuItem />
          </div>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
